<template>
  <div class="flex flex-col">
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'InventoryLogReport'">
      <template v-slot:right-end>
      </template>
    </breadcrumb-base>

    <transition name="fade">
      <div class="content-center">
        <div class="vx-col md:w-4/5 w-full mb-base c-center">
          <vx-card :title="$t('AddInventory')">

            <form-wizard ref="wizard"
                         :start-index="2"
                         @on-complete="formComplete"
                         shape="circle"
                         color="rgba(var(--vs-primary), 1)" :title="null" :subtitle="null"
                         :finishButtonText="$t('Submit')">
              <wizard-step
                slot-scope="props"
                slot="step"
                :tab="props.tab"
                :transition="props.transition"
                :index="props.index">
              </wizard-step>
              <!-- tab 1 content -->
              <tab-content title="Step 1" class="mb-5">
              </tab-content>

              <!-- tab 2 content -->
              <tab-content title="Step 2" class="mb-5">
              </tab-content>

              <!-- tab 3 content -->
              <tab-content title="Step 3" class="mb-5">
                <div class="vx-row">
                  <div class="vx-col justify-center flex w-full" style="text-align: center;">
                    <div class="flex justify-center">
                      <vx-card class="vs-col md:w-auto" style="display: flex" card-background="danger" content-color="#FFFFFF">
                        <feather-icon icon="XIcon"></feather-icon>
                        <p>{{ $t('Invalid') }} <b>{{ invalidPins }}</b> {{ $t('pins') }}</p>
                      </vx-card>
                      <div class="px-4"></div>
                      <vx-card class="vs-col md:w-auto" style="display: flex" card-background="success" content-color="#FFFFFF">
                        <feather-icon icon="CheckIcon"></feather-icon>
                        <p>{{ $t('Valid') }} <b>{{ validPins }}</b> {{ $t('pins') }}</p>
                      </vx-card>
                    </div>
                  </div>
                  <div class="vx-col py-5 justify-center flex w-full" style="text-align: center;" v-if="validPins <= 0">
                    <h5>{{ $t('NoPinsToImported') }}</h5>
                  </div>
                  <div class="vx-col w-full mt-5" v-if="validPins > 0">
                    <div class="vx-col w-full mt-5">
                      <vs-select autocomplete v-model="supplier" class="w-full select-large"
                                 :label="$t('Supplier')"
                                 @input="onSupplierSelected">
                        <vs-select-item :key="index" :value="item.id"
                                        :text="item.full_name" v-for="(item,index) in suppliers"
                                        class="w-full"/>
                      </vs-select>
                    </div>
                    <div class="vx-col w-full mt-5">
                      <vs-input v-validate="'required'" type="number" :label="$t('Discount')"
                                v-model="discount" class="w-full"/>
                    </div>
                  </div>
                </div>
              </tab-content>

              <!--              Custom Footer In Tab-->
              <template slot="footer" slot-scope="props">
                <div class="wizard-footer-left">
                  <!--                  <vs-button v-if="props.activeTabIndex > 0 && !props.isLastStep" @click.native="props.prevTab()" :style="props.fillButtonStyle">Previous</vs-button>-->
                </div>
                <div class="wizard-footer-right">

                  <vs-button v-if="!props.isLastStep" @click.native="props.nextTab()"
                             class="wizard-footer-right" :style="props.fillButtonStyle">
                    {{ $t('Next') }}
                  </vs-button>

                  <vs-button v-if="props.isLastStep && validPins > 0" @click.native="formComplete"
                             class="wizard-footer-right finish-button"
                             :style="props.fillButtonStyle"> {{props.isLastStep ? $t('Done') : $t('Next')}}
                  </vs-button>
                  <vs-button  @click.native="cancelForm"
                              class="wizard-footer-right finish-button mx-2" color="danger"
                              :style="props.fillButtonStyle"> {{ $t('Cancel') }}
                  </vs-button>

                </div>
              </template>
            </form-wizard>

          </vx-card>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "InventoryUploadStep3",
  props: {
    inventoryData: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      importBatch: "",
      invalidPins: "",
      validPins: "",
      discount: "",
      supplier: '',
      breadcrumb: [
        { title: 'Home', i18n: 'Home', url: '/' },
        { title: 'Inventory Log Report', i18n: 'InventoryLogReport', url: '/reports-inventory' },
        { title: 'Inventory Upload', i18n: 'InventoryUpload', active: true },
      ],
    };
  },
  computed: {
    suppliers() {
      return this.$store.state.suppliers.suppliers
    },
    validateStep3() {
      return !this.errors.any() && this.supplier !== '' && this.discount !== null
    },
  },
  methods: {
    onSupplierSelected() {
      const payload = {
        'configuration': this.configuration,
        'supplierId': this.supplier,
      }
      this.$store.dispatch('inventory/fetchSupplierDiscount', payload).then((data) => {
        this.$vs.loading.close()
        if (data.message) {

        } else {
          this.discount = data.discount
        }
      }).catch((error) => {
        this.$vs.loading.close()
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.message ? error.message : error.detail,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    },
    cancelForm() {
      console.log("cancel-form")
      this.$router.push({ name: 'inventory-view' })
    },
    formComplete() {

      console.log("formComplete")
      if (this.validateStep3) {
        this.$vs.loading()
        this.$store.dispatch('inventory/approveInventoryUpload', {
          import_batch: this.importBatch,
          supplierId: this.supplier,
          discount: this.discount,
        }).catch((error) => {
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
          return false
        })
          .then((data) => {
            this.$router.push({ name: 'reports-inventory' })
          })
        return false
      }
      return false
      // if (this.validateStep3) {
      //   const payload = {
      //     supplierId: this.supplier,
      //     discount: this.discount,
      //     batchNo: this.batchNo,
      //   }
      //   this.$vs.loading()
      //   this.$store.dispatch('inventory/uploadStepThree', payload).then((data) => {
      //     this.$vs.loading.close()
      //     this.$vs.notify({
      //       position: 'top-center',
      //       title: 'Success',
      //       text: data.message,
      //       iconPack: 'feather',
      //       icon: 'icon-check-circle',
      //       color: 'success',
      //     })
      //     this.$router.push({ name: 'inventory-view' })
      //   }).catch((error) => {
      //     this.$vs.loading.close()
      //     this.$vs.notify({
      //       position: 'top-center',
      //       title: 'Error',
      //       text: error.response.data.message || error.message,
      //       iconPack: 'feather',
      //       icon: 'icon-alert-circle',
      //       color: 'danger',
      //     })
      //     return false
      //   })
      // }
      return false
    },
    parseInventoryProps() {
      let data = JSON.parse(this.inventoryData);
      this.importBatch = data.import_batch;
      this.validPins = data.successful_pins;
      this.invalidPins = data.unsuccessful_pins;
    },
  },
  mounted() {
    console.log("-----------------------");
    console.log(this.inventoryData);
    console.log("-----------------------");
    const filter = {}
    this.$store.dispatch('suppliers/fetchSuppliers', {
      isMore: false, filter, search: null, totalItems: 100,
    })
      .then((data) => {
        this.$vs.loading.close()
        this.errorFetching = false
      })
      .catch((err) => {
        this.$vs.loading.close()
        this.errorFetching = true
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: error.response.data.message || error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
      })
    this.parseInventoryProps();
  }
}
</script>

<style scoped>

</style>
